import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { PROVIDER_TITLE } from '@constants/common';
import { shuffleArray } from '@helpers/GlobalHelpers';
import './ProvidersList.scss';
import ProvidersListCard from './ProvidersListCard/ProvidersListCard';
import { useProviderData } from '../AvatarProvidersSlider/AvatarProvidersSlider';

export default function ProvidersList({
    count,
    showOnlyCoaches = false,
    size = 8,
}) {
    const [data, setData] = useState();
    const { providers } = useProviderData();
    const { t } = useTranslation();

    useEffect(() => {
        if (providers) {
            const array = showOnlyCoaches
                ? providers.filter((item) =>
                      item.profile.quoteTypes.includes('COACHING')
                  )
                : providers;
            setData(shuffleArray(array).slice(0, size));
        }
    }, [providers]);

    if (!data) return <></>;

    return (
        <div className="ProvidersList">
            {data.map((element, index) => {
                const name =
                    element.userName.firstName +
                    ' ' +
                    element.userName.lastName;
                const picture = element.userInfo.avatar;
                const pictureSharp = element.userInfo.avatarSharp;
                const audio = [{ url: element.profile.providerInfo.audioUrl }];
                const title = t(
                    PROVIDER_TITLE[element.profile.providerInfo.providerTitle]
                );
                const slug = element.profile.providerInfo.slug;

                if (count !== undefined) {
                    if (index < count) {
                        return (
                            <ProvidersListCard
                                key={index}
                                name={name}
                                picture={picture}
                                pictureSharp={pictureSharp}
                                audio={audio}
                                title={title}
                                slug={slug}
                            />
                        );
                    } else {
                        return false;
                    }
                } else {
                    return (
                        <ProvidersListCard
                            key={index}
                            name={name}
                            picture={picture}
                            pictureSharp={pictureSharp}
                            audio={audio}
                            title={title}
                            slug={slug}
                        />
                    );
                }
            })}
        </div>
    );
}
