import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { Box } from '@material-ui/core';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Avatar from '@components/Avatar/Avatar';
import CardSlider from '@components/CardSlider/CardSlider';
import Link from '@components/Link';
import { UserLang, PROVIDER_TITLE } from '@constants/common';
import { shuffleArray } from '@helpers/GlobalHelpers';
import './AvatarProvidersSlider.scss';
import useLocale from '@hooks/useLocale';

interface Props {
    therapyOnly?: boolean;
    avatarOnly?: boolean;
    hasViewMore?: boolean;
    theme?: 'white';
}

export function useProviderData() {
    const [providerData, setProviderData] = useState<any>([]);
    const { currentLanguage } = useLocale();

    const gatsbyRepoData = useStaticQuery(graphql`
        query {
            aepsyApiDe {
                providers(
                    input: {
                        isLive: true
                        size: 200
                        quoteTypes: [COACHING, THERAPY]
                        sortingInput: { type: LANGUAGE }
                    }
                ) {
                    userInfo {
                        firebaseUid
                        avatar
                        avatarSharp {
                            childImageSharp {
                                fluid(
                                    maxWidth: 150
                                    toFormat: WEBP
                                    webpQuality: 90
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    userName {
                        firstName
                        lastName
                    }
                    profile {
                        slug
                        quoteTypes
                        providerInfo {
                            providerTitle
                            audioUrl
                        }
                    }
                }
            }
            aepsyApiEn {
                providers(
                    input: {
                        isLive: true
                        size: 200
                        quoteTypes: [COACHING, THERAPY]
                        sortingInput: { type: LANGUAGE }
                    }
                ) {
                    userInfo {
                        firebaseUid
                        avatar
                        avatarSharp {
                            childImageSharp {
                                fluid(
                                    maxWidth: 150
                                    toFormat: WEBP
                                    webpQuality: 90
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    userName {
                        firstName
                        lastName
                    }
                    profile {
                        slug
                        quoteTypes
                        providerInfo {
                            providerTitle
                            audioUrl
                        }
                    }
                }
            }
            aepsyApiFr {
                providers(
                    input: {
                        isLive: true
                        size: 200
                        quoteTypes: [COACHING, THERAPY]
                        sortingInput: { type: LANGUAGE }
                    }
                ) {
                    userInfo {
                        firebaseUid
                        avatar
                        avatarSharp {
                            childImageSharp {
                                fluid(
                                    maxWidth: 150
                                    toFormat: WEBP
                                    webpQuality: 90
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    userName {
                        firstName
                        lastName
                    }
                    profile {
                        slug
                        quoteTypes
                        providerInfo {
                            providerTitle
                            audioUrl
                        }
                    }
                }
            }
            aepsyApiIt {
                providers(
                    input: {
                        isLive: true
                        size: 200
                        quoteTypes: [COACHING, THERAPY]
                        sortingInput: { type: LANGUAGE }
                    }
                ) {
                    userInfo {
                        firebaseUid
                        avatar
                        avatarSharp {
                            childImageSharp {
                                fluid(
                                    maxWidth: 150
                                    toFormat: WEBP
                                    webpQuality: 90
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    userName {
                        firstName
                        lastName
                    }
                    profile {
                        slug
                        quoteTypes
                        providerInfo {
                            providerTitle
                            audioUrl
                        }
                    }
                }
            }
        }
    `);

    const getDataByLang = () => {
        switch (currentLanguage) {
            case UserLang.English:
                return gatsbyRepoData.aepsyApiEn.providers;

            case UserLang.French:
                return gatsbyRepoData.aepsyApiFr.providers;
            case UserLang.Italian:
                return gatsbyRepoData.aepsyApiIt.providers;
            case UserLang.German:
            default:
                return gatsbyRepoData.aepsyApiDe.providers;
        }
    };

    useEffect(() => {
        const data = getDataByLang();
        setProviderData(data);
    }, [gatsbyRepoData, currentLanguage]);

    return { providers: providerData };
}

const AvatarProvidersSlider: React.FC<Props> = ({
    therapyOnly = false,
    avatarOnly = false,
    hasViewMore = false,
    theme,
}) => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>();

    const { providers } = useProviderData();

    useEffect(() => {
        const array = therapyOnly
            ? providers.filter((item: any) =>
                  item.profile.quoteTypes.includes('THERAPY')
              )
            : providers;
        const tenItems = shuffleArray(array).slice(0, 10);

        setData(
            tenItems.sort((item: any) =>
                Boolean(item.profile.providerInfo.audioUrl) ? -1 : 1
            )
        );
    }, [providers]);
    if (!data) return <></>;

    return (
        <div className="AvatarProvidersSlider">
            <CardSlider
                sliderOption={{
                    centeredSlides: true,
                    initialSlide: 0,
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                    breakpoints: {
                        601: {
                            slidesPerView: 3.5,
                        },
                        801: {
                            slidesPerView: 4.5,
                        },
                        1001: {
                            slidesPerView: 6.3,
                            centeredSlides: false,
                        },
                        1201: {
                            slidesPerView: 7.3,
                            centeredSlides: false,
                        },
                    },
                }}
            >
                {!therapyOnly && (
                    <SwiperSlide>
                        <Avatar
                            align="center"
                            size="m"
                            text={
                                <Box sx={{ ml: 1, mr: 1 }}>
                                    <Title size="s" align="center">
                                        {t('avatar.provider.slider.more')}
                                    </Title>
                                </Box>
                            }
                        />
                    </SwiperSlide>
                )}
                {data &&
                    data.map((item: any) => (
                        <SwiperSlide key={item}>
                            <Avatar
                                align="center"
                                src={item.userInfo.avatar}
                                urlSharp={
                                    item.userInfo.avatarSharp
                                        ? item.userInfo.avatarSharp
                                        : undefined
                                }
                                size="m"
                                audioSrc={item.profile.providerInfo.audioUrl}
                                therapistSlug=""
                            />
                            {!avatarOnly && (
                                <>
                                    <Title
                                        theme={theme}
                                        size="s"
                                        align="center"
                                        noMargin
                                    >{`${item.userName.firstName} ${item.userName.lastName}`}</Title>
                                    {item.profile.providerInfo
                                        .providerTitle && (
                                        <Text
                                            size="s"
                                            align="center"
                                            theme={theme}
                                        >
                                            {t(
                                                PROVIDER_TITLE[
                                                    item.profile.providerInfo
                                                        .providerTitle
                                                ]
                                            )}
                                        </Text>
                                    )}
                                </>
                            )}
                        </SwiperSlide>
                    ))}

                {hasViewMore && (
                    <SwiperSlide>
                        <Link to="/providers">
                            <Avatar
                                className="Avatar--viewMore"
                                align="center"
                                size="m"
                                text={
                                    <Box sx={{ ml: 1, mr: 1 }}>
                                        <Title
                                            size="s"
                                            align="center"
                                            theme="white"
                                        >
                                            👀
                                        </Title>
                                        <Title
                                            size="s"
                                            align="center"
                                            theme="white"
                                        >
                                            {t('button.view.more.experts')}
                                        </Title>
                                    </Box>
                                }
                            />
                        </Link>

                        <Link to="/providers">
                            <Button
                                variant="naked"
                                label={t('show.all')}
                                iconArrow
                                align="center"
                            />
                        </Link>
                    </SwiperSlide>
                )}
            </CardSlider>
        </div>
    );
};

export default AvatarProvidersSlider;
