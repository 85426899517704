import React from 'react';
import Divider from '@atoms/Divider/Divider';
import Module from '@atoms/Module/Module';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Avatar from '@components/Avatar/Avatar';
import './ProvidersListCard.scss';

export default function ProvidersListCard({
    name,
    title,
    picture,
    pictureSharp,
    audio,
}) {
    return (
        <div className="ProvidersListCard">
            <Module radius="l" padding="m">
                <Avatar
                    align="center"
                    src={picture}
                    urlSharp={pictureSharp ? pictureSharp : undefined}
                    size="m"
                    audioSrc={audio ? (audio[0] ? audio[0].url : null) : null}
                    therapistSlug=""
                />

                <Divider invisible spacing="xxs" />

                <Title size="ml" align="left">
                    {name}
                </Title>

                <Text size="s" align="left">
                    {title}
                </Text>
            </Module>
        </div>
    );
}
